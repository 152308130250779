import React from "react"
import { Link } from "react-router-dom"

import imgPattern from "img/pattern.webp"
import { bgImg } from "util"

export default function Foot() {
	return (
		<footer style={ bgImg(imgPattern) } className="bg-[length:600px] py-mt px flex flex-col gap-4 text-white">
			<Link to="/">Hauptseite</Link>
			<Link to="/impressum">Impressum</Link>
			<Link to="/datenschutz">Datenschutz</Link>
		</footer>
	)
}
