import TextBlock from "textBlock"
import Foot from "foot"
import text from "txt/datenschutz"

export default function Datenschutz() {
	return <>
		<main className="bg-rose-2 flex-1 px py">
			<TextBlock title={ text.title } paragraphs={ text.paragraphs } titleBlue />
		</main>
		<Foot />
	</>
}
