import React, { useContext } from "react"
import DesktopNav from "./phoneNav"
import PhoneNav from "./desktopNav"
import { ScreenContext, width } from "context/screenContext"

function Nav({ sections, scrollTo, selected }, ref) {
    const screenWidth = useContext(ScreenContext)
	const horizontal = <PhoneNav sections={ sections } scrollTo={ scrollTo } selected={ selected } />
	const vertical = <DesktopNav sections={ sections } scrollTo={ scrollTo } />
	return <div ref={ ref } className="py-kl px gap-kl bg-rose-1 sticky top-0 w-full">
		{ screenWidth >= width.sm ? horizontal : vertical }
	</div>
}
export default React.forwardRef(Nav)
