import React from "react"

import Foot from "foot"
import Section from "section"
import About from "sections/about"
import Intro from "sections/intro"
import Service from "sections/service"
import Contact from "sections/contact"
import imgLogo from "img/logo.svg"
import Nav from "nav/nav"
import { ScreenContext } from "context/screenContext"
import text from "txt/home"

class SectionContent {
	constructor(title, component, ref, image) {
		this.title = title
		this.component = component
		this.ref = ref
        this.image = image
		this.isVisible = false
	}
}

export default function Home() {

	// a section is a <div> displayed in <main>; here we create the components with refs for the section <div>s
	const sections = [
		new SectionContent(text.intro.name, <Intro />, React.useRef(), imgLogo),
		new SectionContent(text.about.name, <About />, React.useRef()),
		new SectionContent(text.service.name, <Service />, React.useRef()),
		new SectionContent(text.contact.name, <Contact />, React.useRef()),
	]
	const [selected, setSelected] = React.useState(0) // determine which section is highlighted in the navigation bar
    const nav = React.useRef() // navigation bar
	const [navHeight, setNavHeight] = React.useState(0) // we need to get the height of the navigation bar in pixels to know which section is visible at the moment
    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth) // for responsive design

	// get the height of the navigation bar to scroll correctly
	React.useEffect(() => {
		setNavHeight(nav.current.clientHeight)

		// when the users scrolls, check which section is right below the nav
		window.onscroll = () => {
			const sel = document.elementsFromPoint(1, navHeight+1)
			if (!sel.includes(sections[selected].ref.current)) {
				// if selected section isn't in the user's focus, update selected
				for (const i in sections) {
					if (sel.includes(sections[i].ref.current)) {
						setSelected(i)
						return
					}
				}
			}
		}
	})

    // navigation bar uses this to scroll to clicked section
	function scrollTo(section) {
		sections[section].ref.current.scrollIntoView({ behavior: 'smooth' })
	}

	// respond to device
	window.onresize = () => setScreenWidth(window.innerWidth)

	return <ScreenContext.Provider value={ screenWidth }>
		<Nav ref={ nav } sections={ sections } scrollTo={ scrollTo } selected={ selected } />
		<main>
			{ sections.map((sec, i) => 
				<Section key={ i } ref={ sec.ref } navHeight={ navHeight }>
					{ sec.component }
				</Section>
			)}
		</main>
		<Foot />
	</ScreenContext.Provider>
}
