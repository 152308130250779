import { createContext } from 'react';

// https://tailwindcss.com/docs/responsive-design
export const width = {
    'xs': 0,
    'sm': 640,
    'md': 768,
    'lg': 1024,
    'xl': 1280,
    '2xl': 1536,
}
export const ScreenContext = createContext();
