import React from "react"

import TextBlock from "textBlock"
import text from "txt/home"

export default function Service() {
	return <div className="bg-rose-1 px py flex flex-col gap-mt md:gap-gr">
        <TextBlock subtitle={ text.service.subtitle } title={ text.service.botox.title } paragraphs={ text.service.botox.paragraphs } titleBlue />
        <TextBlock title={ text.service.hyaluron.title } paragraphs={ text.service.hyaluron.paragraphs } titleBlue />
    </div>
}
