import React from "react"

import NavElement from "./navElement"

export default function DesktopNav({ sections, selected, scrollTo }) {
	return <div className="flex items-center gap-kl">
		{ sections.map((sec, i) =>
			<NavElement key={ i } image={ sec.image } title={ sec.title } onClick={ () => scrollTo(i) } selected={ i==selected }/>
		)}
	</div>
}
