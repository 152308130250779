const impressum = {
	title: <>Impressum</>,
	paragraphs: [
		[
			<>Regina Berndt</>,
			<>Ostrower Platz 18, 03046 Cottbus</>,
			<>Telefon: 0160 / 740 30 16 </>,
		],
		[
			<>Gesetzliche Berufsbezeichnung: Fachärztin für Kinder und Jugendheilkunde</>,
			<>Berufsbezeichnung verliehen in der Bundesrepublik Deutschland</>,
		],
		[
			<>Zuständige Ärztekammer: Brandenburgische Landesärztekammer</>,
			<>Dreifertstraße 12, 03044 Cottbus</>,
			<>Telefon: 0355 / 78010-0</>,
			<>Telefax: 0355 / 78010-1136</>,
			<>E-Mail: post@laekb.de</>,
			<>Der direkte Link lautet: <a href="www.laekb.de">www.laekb.de</a></>,
		],
		[
			<>Zuständige Kassenärztliche Vereinigung: Kassenärztliche Vereinigung Brandenburg</>,
			<>Der direkte Link lautet: <a href="www.kvbb.de">www.kvbb.de</a></>,
		],
		[
			<>Berufsordnung:</>,
			<>Die für mich geltenden berufsrechtlichen Regelungen sind über die Internetseiten der Brandenburgischen Landesärztekammer zugänglich. <a href="www.laekb.de">Klicken Sie hier</a>, um direkt dorthin zu gelangen.</>,
		],
	],
}
export default impressum
