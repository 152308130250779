import React from "react"

export default function TextBlock({ subtitle, title, paragraphs=[], titleBlue=false }) {

	// title
	let h1Classes = ["text-gr", "font-serif"]
	if (paragraphs.length > 0) {
		h1Classes.push("mb-kl")
	}
	if (titleBlue) {
		h1Classes.push("text-blue")
	}

	// subtitle
	if (subtitle) {
		var h2 = <h2 className="text-mt mb-kl">{ subtitle }</h2>
	} else {
		h2 = null
	}

	// paragraphs
	if (Array.isArray(paragraphs[0])) {
		var p = []
		for (let i of paragraphs.keys()) {
			for (let j of paragraphs[i].keys()) {
				let classes = ['leading-relaxed']
				if (j === 0) classes.push('mt-xkl')
				let para = <p key={ 100*i+j } className={ classes.join(' ') }>{ paragraphs[i][j] }</p>
				p.push(para)
			}
		}
	} else {
		p = paragraphs.map((para, i) =>
			<p key={ i } className="mt-xkl leading-relaxed">{ para }</p>
		)
	}

	return <div>
		{ h2 }
		<h1 className={ h1Classes.join(" ") }>{ title }</h1>
		{ p }
	</div>	
}
