import React, { useContext } from "react"

import TextBlock from "textBlock"
import text from "txt/home"
import face from "img/face.webp"
import faceCrop from "img/facecrop.webp"
import { ScreenContext, width } from "context/screenContext"

const facePixels = [708, 1064] // w, h
const faceCropPixels = [603, 603] // w, h

export default function About() {
    const screenWidth = useContext(ScreenContext)
    const textBlock = <TextBlock title={ text.about.title } subtitle={ text.about.subtitle } paragraphs={ text.about.paragraphs } titleBlue />
    if (screenWidth >= width.lg) {
        // desktop layout
        return <div className="flex bg-rose-2 px py items-start gap-kl">
            <div className="flex-1">
                { textBlock }
            </div>
            <img width={ facePixels[0]+'px' } height={ facePixels[1]+'px' } className="h-[30rem] w-auto" src={ face } alt="" />
        </div>
    } else {
        // mobile layout
        return <div className="bh-rose-2 px py">
            <div className="flex flex-col items-center mb-kl">
                <img width={ faceCropPixels[0]+'px' } height={ faceCropPixels[1]+'px' } className="h-[10rem] w-auto sm:h-[14rem] rounded-full" src={ faceCrop } alt="" />
            </div>
            { textBlock }
        </div>
    }
}
