import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"

import Home from "pages/home"
import Impressum from "pages/impressum"
import Datenschutz from "pages/datenschutz"

export default function App() {
	return <>
		<BrowserRouter>
			<Routes>
				<Route path="/" element={ <Home /> }/>
				<Route path="/impressum" element={ <Impressum /> } />
				<Route path="/datenschutz" element={ <Datenschutz /> } />
			</Routes>
		</BrowserRouter>
	</>
}
