import Foot from "foot"
import TextBlock from "textBlock"
import text from "txt/impressum"

export default function Impressum() {
	return <>
		<main className="bg-rose-2 flex-1 px py">
			<TextBlock title={ text.title } paragraphs={ text.paragraphs } titleBlue />
		</main>
		<Foot />
	</>
}
