import React from "react"
import { Link } from "react-router-dom"

import Hamburger from "img/hamburger"
import Cross from "img/cross"
import NavElement from "./navElement"
import text from "txt/home"

export default function PhoneNav({ sections, scrollTo }) {
	const [isOpen, setOpen] = React.useState(false)
	return <>
		<div className="flex justify-end">
			<button title={ text.nav.open } className="w-6 h-6" onClick={ () => setOpen(true) }><Hamburger /></button>
		</div>
		<div className={`${ isOpen ? 'h-screen' : 'h-0'} z-10 fixed top-0 left-0 w-full transition-[height] overflow-hidden flex`}>
			<div className="py-kl px bg-rose-1 flex-grow">
				<div className="flex justify-end">
					<button title={ text.nav.close } className="w-6 h-6" onClick={ () => setOpen(false) }><Cross /></button>
				</div>
				<div className="py-mt">
					{ sections.map((sec, i) => 
						<p className="mb-kl" key={ i }>
                            <NavElement image={ sec.image } title={ sec.title } onClick={ () => { scrollTo(i); setOpen(false); } } />
                        </p>
					)}
				</div>
				<div className="py-kl">
					<p className="mb-kl">
                        <Link to="/impressum">{ text.impressum.title }</Link>
                    </p>
					<p className="mb-kl">
                        <Link to="/datenschutz">{ text.datenschutz.title }</Link>
                    </p>
				</div>
			</div>
		</div>
		
	</>
}
