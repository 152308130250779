import React from "react"

import TextBlock from "textBlock"
import text from "txt/home"

export default function Contact() {
	return <div className="bg-rose-2 py px">
		<TextBlock title={ text.contact.title } paragraphs={ text.contact.paragraphs } titleBlue />
	</div>
}
