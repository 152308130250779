import React from "react"

import imgPattern from "img/pattern.webp"
import { bgImg } from "util"
import text from "txt/home"
import TextBlock from "textBlock"


export default function Intro() {
	return <div style={ bgImg(imgPattern) } className="px text-white bg-[length:600px] py">
		<TextBlock title={ text.intro.title } subtitle={ text.intro.subtitle } />
	</div>
}
