export function bgImg(img) {
	// https://stackoverflow.com/questions/70805041/background-image-in-tailwindcss-using-dynamic-url-react-js
	return { backgroundImage: `url(${img})` }
}

export function mask(img) {
	return {
		maskImage: `url(${img})`,
		maskSize: '100%',
		WebkitMaskImage: `url(${img})`,
		WebkitMaskSize: '100%',
	}
}
