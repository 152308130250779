import React from "react"

import { mask } from "util"

export default function NavElement({ image, title, onClick, selected }) {
	if (image) {
        // TODO: testen
        // TODO: https://pagespeed.web.dev/analysis/
        // button is image
		var classBase = ["h-kl", "aspect-[346/478]", "transition-colors"]
		var classSelected = ["bg-blue"]
		var classUnselected = ["bg-black", "hover:bg-blue"]
        var style = mask(image)
	} else {
        // button is text
		var classBase = ["transition-colors text-mt font-light"]
		var classSelected = ["text-blue"]
		var classUnselected = ["text-black", "hover:text-blue"]
        var content = title
	}
    return <button title={ title } style={ style } className={ classBase.concat(selected ? classSelected : classUnselected).join(" ") } onClick={onClick}>
        { content }
    </button>
}
